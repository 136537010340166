export class Establishment {
	public id: string;
	public address: string;
	public logo: string;
	public name: string;

	constructor(establishment: any) {
		const { _id, name, logo, address } = establishment;
		this.id = _id;
		this.name = name;
		this.logo = logo;
		this.address = address;
	}
}
