import { Directive, ElementRef, Input, OnChanges, Renderer2, HostListener } from '@angular/core';

@Directive({
	selector: '[appImgFit]'
})
export class ImgFitDirective implements OnChanges {

	@Input() url: string;
	@Input() imgMode: string;
	@Input() rotate: number;
	private img: ElementRef;

	constructor(
		private el: ElementRef,
		private renderer: Renderer2) {}

	ngOnChanges(): void {
		this.triggerImgCalculation();
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.triggerImgCalculation();
	}

	triggerImgCalculation(): void {
		if (this.img) {
			this.renderer.removeChild(this.el.nativeElement, this.img);
		}
		if (this.url) {
			if (this.imgMode === 'cssBack'){
				this.renderer.setStyle(
					this.el.nativeElement,
					'background-image',
					`url(${this.url}?size=${this.el.nativeElement.offsetWidth}x${this.el.nativeElement.offsetHeight}&rotate=${this.rotate}`
					);
			} else {
				this.img = this.renderer.createElement('img');
				let imgUrl;
				if (!this.imgMode) {
					imgUrl = `${this.url}?width=${this.el.nativeElement.offsetWidth}`;
				}
				if (this.imgMode === 'crop') {
					imgUrl = `${this.url}?size=${this.el.nativeElement.offsetWidth}x${this.el.nativeElement.offsetHeight}`;
				}
				if (this.imgMode === 'logo') {
					imgUrl = `${this.url}?width=${this.el.nativeElement.offsetWidth - 40}`;
				}
				this.renderer.setAttribute(this.img, 'src', imgUrl);
				this.renderer.appendChild(this.el.nativeElement, this.img);
			}
		}
	}

}
