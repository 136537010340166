import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LangService } from '../services/lang.service';

@Component({
	selector: 'app-lang-dialog',
	templateUrl: './lang-dialog.component.html',
	styleUrls: ['./lang-dialog.component.scss']
})
export class LangDialogComponent implements OnInit {

	public langs: string[];

	constructor(
		private langService: LangService,
		public dialogRef: MatDialogRef<LangDialogComponent>,
		) { }

	ngOnInit() {
		this.langs = this.langService.getLangsAvailable();
	}

	selectLang(lang: string): void {
		this.dialogRef.close(lang);
	}
}
