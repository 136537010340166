<div class="closeContainer">
  <fa-icon class="close" [icon]="faWindowClose" (click)="closeDialog()"></fa-icon>
</div>
<div class="filterDialogContainer">
  <div *ngIf="filtersSelected.length > 0">
      <div class="title">
        {{ filterSelectedTitle | translation }}
      </div>
      <div>
        <span class="filter active" *ngFor='let filter of filtersSelected' (click)="toggleFilter(filter)">
          {{ filter.name | translation }}
        </span>
      </div>
      <br>
  </div>
  <div>
    <div class="title">
      {{ filterTitle | translation }}
    </div>
    <div>
      <span class="filter" *ngFor='let filter of filters' (click)="toggleFilter(filter)">
        {{ filter.name | translation }}
      </span>
    </div>
  </div>
</div>
