<div class="spinnerCentered" *ngIf="!item">
  <mat-spinner [diameter]="40"></mat-spinner>
</div>
<div *ngIf='item'>
  <div appImgFit [url]="item.image">
  </div>
  <div class="text">
    <div class="title">
      {{ item.name | translation }}
      <span class="heart" *ngIf="item.favorite">
        <fa-icon [icon]="faHeart"></fa-icon>
      </span>
    </div>
    <div class="description">
      {{ item.description | translation }}
    </div>
    <div class="attributes">
      <div *ngIf="item.price">{{item.price | fnCurrency: currency}}</div>
      <div *ngIf="item.prices.length > 0" class="priceTable">
        <table>
          <tr *ngFor="let price of item.prices">
            <td class="label">{{price.label}} </td>
            <td class="price">{{price.price | fnCurrency: currency}}</td>
          </tr>
        </table>
        <br/>
      </div>
      <div *ngIf="item.color && item.year" class="wineColor">
        {{ item.color | translation }}
      </div>
      <div class="filters">
        <span *ngFor="let filter of item.filters">{{filter.name | translation}} </span>
      </div>
    </div>
    <br>
    <div class="separation" *ngIf="item.pairing && item.pairing.length > 0"></div>
    <br>
    <div class="recommandation" *ngIf="item.pairing && item.pairing.length > 0 && getFirstPairingId(item)">
      <h3>{{ advices | translation }}:</h3>
      <ul>
        <li *ngFor="let itemPair of item.pairing">
          <app-item-list [item]="itemPair"></app-item-list>
        </li>
      </ul>
    </div>
  </div>  
</div>
