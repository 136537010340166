import { Pipe, PipeTransform } from '@angular/core';
import { LangService } from '../services/lang.service';

@Pipe({
	name: 'translation',
	pure: false,
})

export class TranslationPipe implements PipeTransform {

	constructor(private translationService: LangService) {}

	public transform(value) {
		return value[this.translationService.getLangSelected()];
	}
}
