<div class="flag" (click)="changeLang()"
  [class.fr]="langSelected === 'fr'"
  [class.en]="langSelected === 'en'"
  [class.es]="langSelected === 'es'"
  [class.it]="langSelected === 'it'"
  [class.ar]="langSelected === 'ar'"
  [class.ru]="langSelected === 'ru'"
  [class.pt]="langSelected === 'pt'"
  [class.fr]="langSelected === 'fr'"
  [class.de]="langSelected === 'de'"
  [class.zh]="langSelected === 'zh'">
</div>
