export class Translation {
		public id: string;
		public fr: string;
		public en: string;
		public es: string;
		public it: string;
		public de: string;
		public pt: string;
		public zh: string;
		public ru: string;

		constructor(translation: any) {
				const { _id , fr, en, es, it, de, pt, zh, ru } = translation;
				this.id = _id;
				this.fr = fr;
				this.en = en;
				this.es = es;
				this.it = it;
				this.de = de;
				this.pt = pt;
				this.zh = zh;
				this.ru = ru;
		}
}
