<div *ngIf="category && category.image" class="imgCategory" appImgFit [url]="category.image" [imgMode]="'cssBack'" [rotate]="90">
</div>
<div class="spinnerCentered" *ngIf="!items">
  <mat-spinner [diameter]="40"></mat-spinner>
</div>
<ul *ngIf="items">
  <li *ngFor="let item of items | filterItems; let i = index">
    <app-item-list [item]="item"></app-item-list>
    <div class="separator"></div>
  </li>
  <li class="emptyItems" [class.display]="isFilterResultZero()">
    {{ sorryMessage | translation }}
    <br>
    <button mat-raised-button (click)="clearAllFilters()">{{clearFilters | translation }}</button>
  </li>
</ul>
