import { NgModule } from '@angular/core';
import { EllipsisModule } from 'ngx-ellipsis';
import { BrowserModule } from '@angular/platform-browser';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { MenuComponent } from './menu/menu.component';
import { ItemComponent } from './item/item.component';
import { FiltersComponent } from './filters/filters.component';
import { CategoryComponent } from './category/category.component';
import { ItemListComponent } from './item-list/item-list.component';
import { NavigationComponent } from './navigation/navigation.component';
import { LangDialogComponent } from './lang-dialog/lang-dialog.component';
import { LangSelectorComponent } from './lang-selector/lang-selector.component';
import { FilterDialogComponent } from './filter-dialog/filter-dialog.component';

import { MenuService } from './services/menu.service';
import { FilterService } from './services/filter.service';

import { ImgFitDirective } from './img-fit.directive';

import { AppRoutingModule } from './app-routing.module';

import { TranslationPipe } from './pipes/translation.pipe';
import { FilterItemsPipe } from './pipes/filter-items.pipe';
import { FnCurrencyPipe } from './pipes/currency.pipe';
import { CurrencyPipe } from '@angular/common';

@NgModule({
	declarations: [
		AppComponent,
		MenuComponent,
		ItemComponent,
		ImgFitDirective,
		TranslationPipe,
    FnCurrencyPipe,
		FilterItemsPipe,
		FiltersComponent,
		CategoryComponent,
		ItemListComponent,
		LangDialogComponent,
		NavigationComponent,
		LangSelectorComponent,
		FilterDialogComponent,
	],
	imports: [
		FormsModule,
		BrowserModule,
		EllipsisModule,
		MatButtonModule,
		MatSelectModule,
		MatDialogModule,
		AppRoutingModule,
		HttpClientModule,
		FontAwesomeModule,
		ReactiveFormsModule,
		DeviceDetectorModule,
		BrowserAnimationsModule,
		MatProgressSpinnerModule,
	],
	providers: [
		HttpClient,
		MenuService,
		FilterService,
		FilterItemsPipe,
    CurrencyPipe,
	],
	bootstrap: [AppComponent],
	entryComponents: [
		LangDialogComponent
	]
})
export class AppModule { }
