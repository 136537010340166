import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MenuService } from '../services/menu.service';
import { Menu } from '../interfaces/menu.interface';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Category } from '../interfaces/category.interface';
import { Establishment } from '../interfaces/establishment.interface';
import { FilterService } from '../services/filter.service';

@Component({
	selector: 'app-navigation',
	templateUrl: './navigation.component.html',
	styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

	categories: Category[];
	menu: Menu;
	establishment: Establishment;
	currentCategory: string;
	logoUrl: string;
	burgerOpen = true;
	@ViewChild('imgContainer') imgContainer: ElementRef;

	constructor(
		private menuService: MenuService,
		private router: Router,
		private filterService: FilterService,
	) { }

	toggleBurger() {
		this.burgerOpen = !this.burgerOpen;
	}

	ngOnInit(): void {
		this.router.events.subscribe((event: any) => {
			if (event instanceof NavigationEnd) {
				if (event.url.split('/').indexOf('category') > -1) {
					const urlSplit = event.url.split('/');
					this.currentCategory = urlSplit[urlSplit.indexOf('category') + 1];
				}
			}
		});
		this.menuService.menu.subscribe((menu: Menu) => {
			this.menu = menu;
			this.categories = menu.categories;
			this.establishment = menu.establishment;
		});
		if (localStorage.getItem('categoryId')) {
			this.currentCategory = localStorage.getItem('categoryId');
		}
	}

	navigateToCategory(category: Category): void {
		localStorage.setItem('categoryId', category.id);
		this.router.navigate([`menu/${this.menu.id}/category/${category.id}`]);
		this.burgerOpen = false;
	}

	setLogoUrl(establishment: Establishment, width: number) {
		this.logoUrl = `${this.establishment.logo}?width=${width - 10}`;
	}

	isCurrentCategory(category: Category) {
		return category.id === this.currentCategory;
	}

}
