<div class="filtersContainer" *ngIf="!isWineDisplay" [class.pad]="isFilterActive()">
  <fa-icon class="logo clear" [icon]="faTimesCircle" *ngIf="isFilterActive()" (click)="clearFilters()"></fa-icon>
  <span *ngFor='let filter of filters' [class.active]="filter.active" (click)="toggleFilter(filter)">
    {{ filter.name | translation }}
  </span>
  <fa-icon class="logo end" [icon]="faPlus" (click)="openFilterDialog()"></fa-icon>
</div>
<div class="filterWineContainer" *ngIf="isWineDisplay">
  <div class="selectContainer">
    <mat-form-field>
      <mat-label>{{ colorTranslation | translation}}</mat-label>
      <mat-select (selectionChange)="onFilterChanges()" [(ngModel)]="colorSelected" name="colors">
        <mat-option *ngFor="let color of colors" [value]="color">
          {{ color | translation }}
        </mat-option>
        <mat-option [value]="null">
          {{ noColor | translation }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="selectContainer">
    <mat-form-field>
      <mat-label>{{regionTranslation | translation}}</mat-label>
      <mat-select (selectionChange)="onFilterChanges()" [(ngModel)]="regionSelected" name="regions">
        <mat-option *ngFor="let region of regions" [value]="region">
          {{ region.name }}
        </mat-option>
        <mat-option [value]="null">
          {{ noRegion | translation }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="selectContainer">
    <mat-form-field>
      <mat-label>{{ tagTranslation | translation }}</mat-label>
      <mat-select (selectionChange)="onFilterChanges()" [(ngModel)]="tagsSelected" name="tags" multiple>
        <mat-option *ngFor="let tag of filters" [value]="tag">
          {{ tag.name | translation }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>