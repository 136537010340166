import { Translation } from './translation.interface';
import { Filter } from './filter.interface';
import { Price } from './price.interface';
import { Region } from './region.interface';

export class Item {
	public id: string;
	public name: Translation;
	public filters: Filter[];
	public description?: Translation;
	public createdAt?: Date;
	public updatedAt?: Date;
	public price: number;
	public prices: Price[];
	public image: string;
	public favorite: boolean;
	public pairing: Item[] | string[];
	public color: Translation | null;
	public region: Region | null;
	public year: number | null;

	constructor(item, isReco = false) {
		const {
			_id,
			_name,
			_description,
			_filters,
			_pairing,
			price,
			prices,
			image,
			favorite,
			_color,
			_region,
			createdAt,
			updatedAt,
			year,
		} = item;
		this.id = _id;
		this.name = new Translation(_name);
		if (!isReco) {
			this.filters = _filters.map(filter => new Filter(filter));
			if (_pairing && _pairing.length > 0) {
				this.pairing =_pairing.map((itemPair) => itemPair && itemPair._id ? new Item(itemPair, true) : itemPair);
			} else {
				this.pairing = null;
			}
		}
		this.description = _description ? new Translation(_description) : null;
		this.price = price;
		this.prices = prices.map(newPrice => new Price(newPrice));
		this.image = image;
		this.favorite = favorite ? favorite : false;
		this.color = _color ? new Translation(_color) : null;
		this.region = _region ? new Region(_region) : null;
		this.year = year != 0 ? year : null;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
	}
}
