import { Pipe, PipeTransform } from '@angular/core';
import { intersection } from 'lodash';
import { FilterService } from '../services/filter.service';
import { Item } from '../interfaces/item.interface';

@Pipe({
	name: 'filterItems',
	pure: false,
})

export class FilterItemsPipe implements PipeTransform {

	constructor(private filterService: FilterService) {}

	public transform(items: Item[]) {
		const filters = this.filterService.activeFilters;
		const color = this.filterService.activeColor;
		const region = this.filterService.activeRegion;
		let itemsWithFilters = items;
		if (color && items) {
			itemsWithFilters = itemsWithFilters.filter(item => item.color.fr === color.fr );
		}
		if (region && items) {
			itemsWithFilters = itemsWithFilters.filter(item => region.id === item.region.id);
		}
		if (filters.length > 0 && items) {
			itemsWithFilters = itemsWithFilters.filter((item) => {
				return intersection(
					item.filters.map(filter => filter.id),
					filters.map(tag => tag.id)
				).length === filters.length;
			});
		}

		if (filters.length === 0 && !color && !region && items ) {
			return items;
		} else {
			return itemsWithFilters;
		}
	}
}
