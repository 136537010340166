import { Translation } from './translation.interface';

export class Filter {
		public id: string;
		public name: Translation;
		public category: string;
		public active = false;

		constructor(filter) {
				const { _id, _name, _category } = filter;
				this.id = _id;
				this.name = new Translation(_name);
				this.category = _category;
		}
}
