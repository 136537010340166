export class Region {
	public id: string;
	public image: string;
	public name: string;

	constructor(newRegion) {
		const {
			_id,
			image,
			name
		} = newRegion;
		this.id = _id;
		this.image = image;
		this.name = name;
	}
}
