import { Translation } from './translation.interface';
import { Filter } from './filter.interface';
import { flattenDeep, uniqWith, isEqual } from 'lodash';
import { Item } from './item.interface';

export class Category {
		public id: string;
		public name: Translation;
		public items: any[];
		public filters: Filter[];
		public description?: Translation;
		public image: string;
		public type: string | null;
		public createdAt?: Date;
		public updatedAt?: Date;

		constructor(category) {
			const { _id, _name, items, _description, image, type, createdAt, updatedAt } = category;
			this.id = _id;
			this.name = new Translation(_name);
			this.items = items.map((item) => new Item(item));
			this.filters = uniqWith(flattenDeep(category.items
					.map(item => item._filter ? item._filters.map(filter => new Filter(filter)) : null)
			), isEqual);
			this.type = type ? type : null;
			this.image = image;
			this.description = _description ? new Translation(_description) : null;
			this.createdAt = createdAt;
			this.updatedAt = updatedAt;
		}
}
