import { Component, OnInit, Input } from '@angular/core';
import { Item } from '../interfaces/item.interface';
import { MenuService } from '../services/menu.service';
import { Price } from '../interfaces/price.interface';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { FilterService } from '../services/filter.service';

@Component({
	selector: 'app-item-list',
	templateUrl: './item-list.component.html',
	styleUrls: ['./item-list.component.scss']
})
export class ItemListComponent implements OnInit {

	@Input() item: Item;
	public currency = '€';
	public faHeart = faHeart;

	constructor(
		private menuService: MenuService,
		private router: Router,
		private filterService: FilterService,
	) {}

	ngOnInit(): void {
		this.menuService.menu.subscribe((menu) => {
			this.currency = menu.currency;
		});
	}

	getLowest(prices: Price[]): number {
		return Math.min.apply(Math, prices.map(price => price.price));
	}

	getBiggest(prices: Price[]): number {
		return Math.max.apply(Math, prices.map(price => price.price));
	}

	getImgUrl(imageUrl: string, containerWidth: number, containerHeight: number): string {
		return `${imageUrl}?size=${containerWidth}x${containerHeight}`;
	}

	navigateToItem(item: Item) {
		this.filterService.clearFilters();
		this.filterService.displayFiltersSubject.next('none'),
		this.filterService.hideFilters();
		this.router.navigate([`menu/${localStorage.getItem('menuId')}/item/${item.id}`]);
	}
}
