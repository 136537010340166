export class Price {
	public id: string;
	public label: string;
	public price: string;

	constructor(newPrice) {
		const { _id, label, price } = newPrice;
		this.id = _id;
		this.label = label;
		this.price = price;
	}
}
