import { Component, OnInit } from '@angular/core';
import { MenuService } from '../services/menu.service';
import { ActivatedRoute } from '@angular/router';
import { Item } from '../interfaces/item.interface';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

import { get } from 'lodash';

@Component({
	selector: 'app-item',
	templateUrl: './item.component.html',
	styleUrls: ['./item.component.scss']
})
export class ItemComponent implements OnInit {

	public item: Item;
	public currency = '€';
	public faHeart = faHeart;
	public advices = {
		fr: 'Recommandations ',
		en: 'Advices',
		ar: 'التوصيات',
		ru: 'Рекомендации',
		zh: '建议',
		it: 'Consigli',
		es: 'Recomendaciones',
		pt: 'Recomendações',
		de: 'Ampfehlungen',
	};

	constructor(
		private menuService: MenuService,
		private route: ActivatedRoute,
	) { }

	ngOnInit(): void {
		this.menuService.getItem(this.route.snapshot.paramMap.get('idItem'))
			.subscribe((item: Item) => {
				this.item = item;
			});
		this.menuService.menu.subscribe((menu) => {
			this.currency = menu.currency;
		});
	}

	getFirstPairingId(item: Item) {
		return get(item, 'pairing[0].id', null);
	}

}
