import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
	name: 'fnCurrency',
	pure: false,
})

export class FnCurrencyPipe implements PipeTransform {

  constructor(private currencyPipe: CurrencyPipe) {}

	public transform(value, currency) {
    if (value) {
      let currencyString = value.toString();
      if (value.toString().split('.')[1]) {
        const digits = value.toString().split('.')[1]
        if (digits.length === 1)
          currencyString += '0';
      }
      switch(currency) {
        case ' €':
        case '€':
          currencyString = `${currencyString.replace('.', ',')} €`;
          break
        case '$':
          currencyString = `$${currencyString}`;
          break;
        case 'CFA':
          currencyString = `${currencyString} CFA`;
          break;
        default:
          currencyString = `${currencyString.replace('.', ',')} €`;
      }
      return currencyString;
    }
  }
}
