import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuService } from '../services/menu.service';
import { Menu } from '../interfaces/menu.interface';

@Component({
	selector: 'app-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

	menuId: string;
	establishmentId: string;
	menu: Menu;

	constructor(
		private route: ActivatedRoute,
		private menuService: MenuService,
		private router: Router,
		) { }

	ngOnInit(): void {
		this.menuId = this.route.snapshot.paramMap.get('idMenu');

		this.menuService.getMenu(this.menuId).subscribe((menu) => {
			this.menu = menu;
			if (!localStorage.getItem('categoryId')) {
				localStorage.setItem('categoryId', this.menu.categories[0].id);
				this.router.navigate([`menu/${this.menu.id}/category/${this.menu.categories[0].id}`]);
			}
		});
	}

}
