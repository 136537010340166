import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import { Filter } from './interfaces/filter.interface';
import { FilterService } from './services/filter.service';
import { Translation } from './interfaces/translation.interface';
import { Region } from './interfaces/region.interface';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	title = 'foodnovate-qr';
	public filters: Filter[];
	public colors: Translation[];
	public regions: Region[];
	public displayFilters: boolean;
	public isFilterDisplay = false;
	public isWineFilterDisplay = false;
	public faArrowLeft = faArrowLeft;
	public isReturn = true;

	constructor(
		private filterService: FilterService,
		private router: Router,
		private location: Location,
	) {}

	goBack(): void {
		this.location.back();
	}

	ngOnInit() {
		this.router.events.subscribe((event: RouterEvent) => {
			if (event instanceof NavigationEnd) {
				if (event.url.split('/').indexOf('item') > -1) {
					this.displayFilters = false;
					this.isReturn = true;
				}
				if (event.url.split('/').indexOf('category') > -1) {
					this.displayFilters = true;
					this.isReturn = false;
				}
			}
		});
		this.filterService.filters.subscribe((filters: Filter[]) => {
			if (filters.length === 0) {
				this.displayFilters = false;
				this.isFilterDisplay = false;
			} else {
				this.isFilterDisplay = true;
				this.filters = filters;
			}
		});
		this.filterService.wineColorFilters.subscribe((colors: Translation[]) => {
			this.colors = colors;
			if (this.colors && this.colors.length > 0) {
				this.displayFilters = true;
			}
		});
		this.filterService.wineRegionFilters.subscribe((regions: Region[]) => {
			this.regions = regions;
			if (this.regions && this.regions.length > 0) {
				this.displayFilters = true;
			}
		});
		this.filterService.displayFiltersSubject.subscribe((display: string) => {
			if (display === 'filters') {
				this.isFilterDisplay = true;
				this.isWineFilterDisplay = false;
			} else if (display === 'wineFilters') {
				this.isFilterDisplay = false;
				this.isWineFilterDisplay = true;
			} else {
				this.isFilterDisplay = false;
				this.isWineFilterDisplay = false;
			}
		});
	}
}
