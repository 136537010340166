import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class LangService {

	public currentLangSubject = new Subject();
	public langSelected: string;
	private langsAvailable: string[];

	constructor() {}

	setLangAvailabe(langs: string[]) {
		this.langsAvailable = langs;
	}

	getLangsAvailable(): string[] | null {
		return this.langsAvailable ? this.langsAvailable : null;
	}

	setLangSelected(lang: string) {
		localStorage.setItem('langSelected', lang);
		this.langSelected = lang;
		this.currentLangSubject.next(this.langSelected);
	}

	getLangSelected(): string | null {
		if (!this.langSelected) {
			this.langSelected = localStorage.getItem('langSelected') ? localStorage.getItem('langSelected') : null;
			return this.langSelected;
		}
		return this.langSelected;
	}
}
