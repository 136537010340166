import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ApiUriService } from './api-uri.service';
import { Observable, throwError, Subject } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';

import { LangService } from './lang.service';

import { Menu } from '../interfaces/menu.interface';
import { Item } from '../interfaces/item.interface';
import { Filter } from '../interfaces/filter.interface';
import { Category } from '../interfaces/category.interface';

@Injectable({
	providedIn: 'root'
})
export class MenuService {

	private apiBaseUrl: string;
	public menu: Subject<Menu> = new Subject();

	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		}),
	};

	constructor(
		private apiUriService: ApiUriService,
		private http: HttpClient,
		private langService: LangService,
		) {
		this.apiBaseUrl = this.apiUriService.getApiUri();
	}

	getMenu(menuId: string): Observable<Menu> {
		const localMenu = JSON.parse(localStorage.getItem('menu'));
		if (localMenu && localMenu.id === menuId) {
			const menuJson = JSON.parse(localStorage.getItem('menu'));
			const newMenu = new Menu(menuJson);
			const langs = newMenu.lang.filter((lang) => lang !== 'ar');
			this.langService.setLangAvailabe(langs);
			this.langService.setLangSelected(newMenu.langDefault);
			this.menu.next(newMenu);
			return new Observable((observer) => {
				observer.next(newMenu);
			});
		}
		return this.http.get<any>(`${this.apiBaseUrl}menus/${menuId}?expand=true&expendCategories=true`)
			.pipe(
				retry(1),
				catchError(this.errorHandle),
				map( (menu: any) => {
					localStorage.setItem('menu', JSON.stringify(menu));
					const newMenu = new Menu(menu);
					localStorage.setItem('menuId', newMenu.id);
					const langs = newMenu.lang.filter((lang) => lang !== 'ar');
					this.langService.setLangAvailabe(langs);
					this.langService.setLangSelected(newMenu.langDefault);
					this.menu.next(newMenu);
					return newMenu;
				}),
			);
	}

	getItemsFromCategory(idCategory: string): Observable<Item[]> {
		return this.http.get<Item[]>(`${this.apiBaseUrl}categories/${idCategory}/items`)
			.pipe(
				retry(1),
				catchError(this.errorHandle),
				map((items) => items.map(item => new Item(item)))
			);
	}

	getFiltersFromCategory(idCategory: string): Observable<Filter[]> {
		return this.http.get<Filter[]>(`${this.apiBaseUrl}categories/${idCategory}/filters`)
		.pipe(
			retry(1),
			catchError(this.errorHandle),
			map((filters) => filters.map(filter => new Filter(filter)))
		);
	}

	getItem(idItem: string): Observable<Item> {
		return this.http.get<Item[]>(`${this.apiBaseUrl}items/${idItem}`)
			.pipe(
				retry(1),
				catchError(this.errorHandle),
				map((item) => new Item(item))
			);
	}

	getCategory(idCategory: string): Observable<Category> {
		return this.http.get<Category>(`${this.apiBaseUrl}categories/${idCategory}?expand=true`)
			.pipe(
				retry(1),
				catchError(this.errorHandle),
				map((category) => new Category(category)),
			);
	}

	errorHandle(error: any) {
		let errorMessage = '';
		if (error.error instanceof ErrorEvent) {
			errorMessage = error.error.message;
		} else {
			errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
		}
		console.log(errorMessage);
		return throwError(errorMessage);
	}

}
