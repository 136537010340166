<div class="curtain" [class.isDisplay]="burgerOpen" (click)="toggleBurger()" ></div>
<div id="drawer" class="nav" [class.close]="!burgerOpen">
    <div class="burgerButton" (click)="toggleBurger()" [class.open]="burgerOpen">
        <div class="bar1"></div>
        <div class="bar2"></div>
        <div class="bar3"></div>
    </div>
    <div *ngIf="!categories">
        <div class="spinnerCentered">
            <mat-spinner [diameter]="40"></mat-spinner>
        </div>
    </div>
    <div *ngIf="categories" class="drawerContent">
        <div
            #img
            appImgFit
            [url]="establishment.logo"
            [imgMode]="'logo'"
            class="establishmentLogo"
            *ngIf="establishment">
        </div>
        <div class="navigationLinks">
            <ul *ngIf="categories">
                <li *ngFor="let category of categories">
                    <a
                        (click)="navigateToCategory(category)"
                        [class.active]="isCurrentCategory(category)">
                        <span *ngIf="isCurrentCategory(category)">--- </span>{{ category.name | translation }}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>
