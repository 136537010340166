import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MenuComponent } from './menu/menu.component';
import { ItemComponent } from './item/item.component';
import { CategoryComponent } from './category/category.component';

const routes: Routes = [
	{ path: 'menu/:idMenu',
		component: MenuComponent,
		children: [
			{
				path: 'category/:idCategory',
				component: CategoryComponent,
			},
			{
				path: 'item/:idItem',
				component: ItemComponent,
			}
		]
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
