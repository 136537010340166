import { Translation } from './translation.interface';
import { Establishment } from './establishment.interface';
import { Category } from './category.interface';

export class Menu {
		public id: string;
		public name: Translation;
		public description?: Translation;
		public createdAt?: Date;
		public updatedAt?: Date;
		public lang: string[];
		public langDefault: string;
		public currency: string;
		public categories: Category[];
		public establishment: Establishment;

		constructor(menu) {
				const {
						_id,
						_name,
						lang,
						langDefault,
						categories,
						currency,
						_description,
						createdAt,
						updatedAt,
						_establishment,
				} = menu;
				this.id = _id;
				this.name = new Translation(_name);
				this.lang = lang;
				this.langDefault = langDefault;
				this.currency = currency;
				this.categories = categories.map((cat) => new Category(cat));
				this.description = _description ? new Translation(_description) : null;
				this.createdAt = createdAt;
				this.updatedAt = updatedAt;
				this.establishment = new Establishment(_establishment);
		}
}
