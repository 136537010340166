import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Filter } from '../interfaces/filter.interface';
import { Region } from '../interfaces/region.interface';
import { Translation } from '../interfaces/translation.interface';

@Injectable({
	providedIn: 'root'
})
export class FilterService {

	public filters = new Subject();
	public wineColorFilters = new Subject();
	public wineRegionFilters = new Subject();
	public clearFiltersSubject = new Subject();
	public displayFiltersSubject = new Subject();
	public activeFilters: Filter[] = [];
	public activeColor: Translation;
	public activeRegion: Region;

	constructor() {}

	displayFilters(filters: Filter[]): void {
		this.filters.next(filters);
	}

	hideFilters(): void {
		this.filters.next([]);
		this.wineColorFilters.next(null);
		this.wineRegionFilters.next(null);
	}

	setFilters(filters: Filter[]): void {
		this.activeFilters = filters;
	}
	setColor(colors: Translation): void {
		this.activeColor = colors;
	}

	setRegion(regions: Region): void {
		this.activeRegion = regions;
	}

	addFilter(filter: Filter): void {
		this.activeFilters.push(filter);
	}

	removeFilter(filter: Filter): void {
		this.activeFilters = this.activeFilters.filter((tag) => tag.id !== filter.id);
	}

	clearFilters(): void {
		this.activeFilters = [];
		this.activeRegion = null;
		this.activeColor = null;
	}

}
