import { Component, OnInit } from '@angular/core';

import { LangService } from '../services/lang.service';
import { MatDialog } from '@angular/material/dialog';
import { LangDialogComponent } from '../lang-dialog/lang-dialog.component';

@Component({
	selector: 'app-lang-selector',
	templateUrl: './lang-selector.component.html',
	styleUrls: ['./lang-selector.component.scss']
})
export class LangSelectorComponent implements OnInit {

	public langSelected: string;

	constructor(
		private langService: LangService,
		private dialog: MatDialog) {}

	ngOnInit(): void {
		this.langService.currentLangSubject.subscribe((lang: string) => {
			this.langSelected = lang;
		});
	}

	changeLang() {
		const dialogRef = this.dialog.open(LangDialogComponent, {
			width: 'calc(100vw - 50px)',
		});

		dialogRef.afterClosed().subscribe(lang => {
			this.langSelected = lang ? lang : this.langSelected;
			this.langService.setLangSelected(this.langSelected);
		});
	}

}
