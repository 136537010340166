import { Subscription, Subject } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

import { Item } from '../interfaces/item.interface';
import { MenuService } from '../services/menu.service';
import { Filter } from '../interfaces/filter.interface';
import { FilterService } from '../services/filter.service';
import { Category } from '../interfaces/category.interface';
import { FilterItemsPipe } from '../pipes/filter-items.pipe';


@Component({
	selector: 'app-category',
	templateUrl: './category.component.html',
	styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit, OnDestroy {

	public items: Item[];
	public category: Category;
	private categoryId: string;

	private routerSub: Subscription;
	private catSub: Subscription;
	private filtersSub: Subscription;
	private categoryIdSub = new Subject();

	public clearFilters = {
		fr: 'Re-initialiser les filtres',
		en: 'Clear filters',
		ar: 'مرشحات واضحة',
		ru: 'Очистить фильтры',
		zh: '清除筛选',
		it: 'Cancella filtri',
		es: 'Filtros claros',
		pt: 'Limpar filtros',
		de: 'Filter löschen',
	};

	public sorryMessage = {
		fr: 'Navré aucun produit ne correspond à votre selection',
		en: 'Sorry, no product found',
		ar: 'عذرا ، لم يتم العثور على منتج',
		ru: 'Извините, продукт не найден',
		zh: '对不起，没有找到产品',
		it: 'Siamo spiacenti, nessun prodotto trovato',
		es: 'Lo siento, no se ha encontrado ningún producto',
		pt: 'Desculpe, nenhum produto encontrado',
		de: 'Leider wurde kein Produkt gefunden',
	};

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private menuService: MenuService,
		private filterService: FilterService,
		private filterItemPipe: FilterItemsPipe,
	) { }

	ngOnInit(): void {
		this.categoryIdSub.subscribe((catId: string) => {
			this.filterService.clearFilters();
			this.categoryId = catId;
			this.catSub = this.menuService.getCategory(this.categoryId).subscribe((category: Category) => {
				this.category = category;
				this.filtersSub = this.menuService.getFiltersFromCategory(catId).subscribe((filters: Filter[]) => {
					this.filterService.filters.next(filters);
					if (this.category.type === 'wineList') {
						const colors = this.removeDuplicates(this.category.items.map((item: Item) => item.color), 'fr');
						const regions = this.removeDuplicates(this.category.items.map((item: Item) => item.region), 'id');
						this.filterService.wineColorFilters.next(colors);
						this.filterService.wineRegionFilters.next(regions);
					} else {
						this.filterService.wineColorFilters.next(null);
						this.filterService.wineRegionFilters.next(null);
					}
				});
				this.items = this.category.items;
			});
		});
		this.categoryIdSub.next(this.route.snapshot.paramMap.get('idCategory'));
		this.routerSub = this.router.events.subscribe((event: any) => {
			if (event instanceof NavigationEnd) {
				const urlSplit = event.url.split('/');
				if (urlSplit.indexOf('item') > -1) {
					this.routerSub.unsubscribe();
					this.categoryIdSub.subscribe();
				} else {
					window.scroll(0, 0);
					this.categoryIdSub.next(urlSplit[urlSplit.indexOf('category') + 1]);
				}
			}
		});
	}

	ngOnDestroy(): void {
		this.catSub.unsubscribe();
		this.filtersSub.unsubscribe();
	}

	removeDuplicates(myArr: any[], prop: string) {
		const newArray = [];
		myArr.forEach((elem) => {
			if (newArray.map(newElem => newElem[prop]).indexOf(elem[prop]) === -1) {
				newArray.push(elem);
			}
		});
		return newArray;
	}

	isFilterResultZero(): boolean {
		return this.filterItemPipe.transform(this.items).length === 0;
	}

	clearAllFilters(): void {
		this.filterService.clearFiltersSubject.next(true);
	}
}
