import { MatDialogRef } from '@angular/material/dialog';
import { Filter } from '../interfaces/filter.interface';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';

@Component({
	selector: 'app-filter-dialog',
	templateUrl: './filter-dialog.component.html',
	styleUrls: ['./filter-dialog.component.scss']
})
export class FilterDialogComponent implements OnInit {

	public filters: Filter[] = [];
	public filtersSelected: Filter[];
	public idFiltersActive: string[];
	public faWindowClose = faWindowClose;

	public filterTitle = {
		fr: 'Filtres :',
		en: 'Filtres:',
		ar: 'الترشيح:',
		ru: 'фильтры на:',
		zh: '过滤器:',
		it: 'Filtri :',
		es: 'Filtros :',
		pt: 'Filtos :',
		de: 'Filter :',
	};

	public filterSelectedTitle = {
		fr: 'Filtres séléctionnés :',
		en: 'Selected filters:',
		ar: 'مرشحات مختارة:',
		ru: 'Выбранные фильтры:',
		zh: '选定的过滤器:',
		it: 'Filtri selezionati :',
		es: 'Color vinoFiltros seleccionados :',
		pt: 'Filtros selecionados :',
		de: 'Ausgewählte Filter :',
	};

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<FilterDialogComponent>,
		) { }

	ngOnInit(): void {
		this.idFiltersActive = this.data.activeFilters.map((filter) => filter.id);
		this.filters = this.data.filters.filter((filter) => this.idFiltersActive.indexOf(filter.id) === -1);
		this.filtersSelected = this.data.activeFilters;
		console.log('filters', this.filters);
		console.log('filters selected', this.filtersSelected);
	}

	toggleFilter(filter: Filter): void {
		if (this.idFiltersActive.indexOf(filter.id) > -1) {
			filter.active = false;
			this.filtersSelected = this.filtersSelected.filter((tag: Filter) => tag.id !== filter.id);
		} else {
			filter.active = true;
			this.filtersSelected.push(filter);
		}
		this.idFiltersActive = this.filtersSelected.map((tag) => tag.id);
	}

	closeDialog() {
		this.dialogRef.close();
	}

}
