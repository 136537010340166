import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ApiUriService {

	private apiUriDev = 'https://foodnovate-api.herokuapp.com/';
	// private apiUriDev = 'http://localhost:8888/';
	private apiUriProd = 'https://foodnovate-api.herokuapp.com/';

	constructor() { }

	getApiUri(): string {
		if (environment.production) {
			return this.apiUriProd;
		} else {
			return this.apiUriDev;
		}
	}
}
