<header>
  <fa-icon [icon]="faArrowLeft" *ngIf="isReturn" (click)="goBack()"></fa-icon>
  <app-lang-selector class="langSelector"></app-lang-selector>
  <app-filters
    *ngIf="displayFilters"
    [filters]="filters"
    [colors]="colors"
    [regions]="regions">
  </app-filters>
</header>
<nav>
  <app-navigation></app-navigation>
</nav>
<main [class.withFilters]="isFilterDisplay" [class.withWineFilters]="isWineFilterDisplay">
  <router-outlet></router-outlet>
</main>
<footer></footer>