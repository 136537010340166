import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { faTimesCircle, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Component, OnChanges, Input, OnDestroy } from '@angular/core';

import { Filter } from '../interfaces/filter.interface';
import { Region } from '../interfaces/region.interface';
import { FilterService } from '../services/filter.service';
import { Translation } from '../interfaces/translation.interface';
import { FilterDialogComponent } from '../filter-dialog/filter-dialog.component';



@Component({
	selector: 'app-filters',
	templateUrl: './filters.component.html',
	styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnChanges, OnDestroy {

	@Input() filters: Filter[];
	@Input() colors: Translation[];
	@Input() regions: Region[];

	public faPlus = faPlus;
	public regionSelected: Region;
	public isWineDisplay = false;
	public colorSelected: Translation;
	public tagsSelected: Filter[] = [];
	public faTimesCircle = faTimesCircle;
	private clearFiltersSub: Subscription;

	public colorTranslation = {
		fr: 'Couleur',
		en: 'Wine Color',
		ar: 'لون النبيذ',
		ru: 'цвет вина',
		zh: '酒色',
		it: 'Colore del vino',
		es: 'Color vino',
		pt: 'Cor de vinho',
		de: 'Weinfarbe',
	};

	public noColor = {
		fr: 'Aucune',
		en: 'Clear',
		ar: 'لا لون',
		ru: 'Нет цвета',
		zh: '没有颜色',
		it: 'Nessun colore',
		es: 'No color',
		pt: 'Sem cor',
		de: 'Keine Farbe',
	};

	public regionTranslation = {
		fr: 'Region',
		en: 'Origin',
		ar: 'لا مصدر',
		ru: 'происхождения',
		zh: '来源',
		it: 'Provenienza',
		es: 'Procedencia',
		pt: 'Origem',
		de: 'Der ursprung',
	};

	public noRegion = {
		fr: 'Pas de region',
		en: 'No origin',
		ar: 'لا يوجد العناية',
		ru: 'без провидения',
		zh: '没有天意',
		it: 'No provenienza',
		es: 'No procedencia',
		pt: 'Sem origem',
		de: 'Keine ursprung',
	};

	public tagTranslation = {
		fr: 'Tags',
		en: 'Tags',
		ar: 'وسم',
		ru: 'бирка',
		zh: '标签',
		it: 'Cartellino',
		es: 'Marbete',
		pt: 'Etiqueta',
		de: 'Etikett',
	};

	constructor(
		private filterService: FilterService,
		private dialog: MatDialog,
		) {}

	ngOnDestroy(): void {
		this.clearFiltersSub.unsubscribe();
	}

	ngOnChanges(): void {
		this.clearFiltersSub = this.filterService.clearFiltersSubject.subscribe((doClearFilters: boolean) => {
			if (doClearFilters) {
				this.tagsSelected = [];
				this.colorSelected = null;
				this.regionSelected = null;
				this.filterService.clearFilters();
			}
		});
		if (this.filters && this.filters.length > 0) {
			this.isWineDisplay = false;
			this.filterService.displayFiltersSubject.next('filters');
		}
		if (this.colors) {
			this.filterService.displayFiltersSubject.next('wineFilters');
			this.isWineDisplay = true;
		}
		if (this.regions) {
			this.filterService.displayFiltersSubject.next('wineFilters');
			this.isWineDisplay = true;
		}
	}

	toggleFilter(tag: Filter): void {
		const listOfIdFilters = this.filterService.activeFilters.map(newTag => newTag.id);
		if (listOfIdFilters.indexOf(tag.id) === -1) {
			this.filterService.addFilter(tag);
		} else {
			this.filterService.removeFilter(tag);
		}
		tag.active = !tag.active;
	}

	toggleFilters(tags: Filter[]): void {
		const listOfIdFilters = this.filterService.activeFilters.map(newTag => newTag.id);
		tags.forEach((tag) => {
			if (listOfIdFilters.indexOf(tag.id) === -1) {
				this.filterService.addFilter(tag);
			} else {
				this.filterService.removeFilter(tag);
			}
			tag.active = !tag.active;
		});
	}

	onFilterChanges(): void {
		this.filterService.setFilters(this.tagsSelected);
		this.filterService.setColor(this.colorSelected);
		this.filterService.setRegion(this.regionSelected);
	}

	isFilterActive(): boolean {
		if (this.filters) {
			return this.filters.filter(filter => filter.active).length > 0;
		} else {
			return false;
		}
	}

	clearFilters(): void {
		this.filterService.activeFilters = this.filterService.activeFilters.map((filter) => {
			filter.active = false;
			return filter;
		});
		this.filterService.clearFilters();
	}

	openFilterDialog(): void {
		console.log('Open filter dialog');

		const dialogRef = this.dialog.open(FilterDialogComponent, {
			width: '100vw',
			height: '100vh',
			data: {
				filters: this.filters,
				activeFilters: this.filterService.activeFilters,
			},
		});
	}
}
