<div class="itemContainer">
  <a (click)="navigateToItem(item)">
    <div class="texts" #leftContainer [class.isImg]='item.image'>
    <div class="title" *ngIf="item.name">
      {{item.name | translation }}
      <span class="heart" *ngIf="item.favorite">
        <fa-icon [icon]="faHeart"></fa-icon>
      </span>
    </div>
    <!-- <div class="description" *ngIf="item.description" ellipsis [ellipsis-content]="item.description | translation"> -->
      <div class="description" *ngIf="item.description">
        {{ item.description | translation }}
      </div>
    <!-- </div> -->
    <div class="attributes">
      <div *ngIf="item.price">{{item.price | fnCurrency:currency}}</div>
      <div *ngIf="item.prices.length > 1">
        {{getLowest(item.prices) | fnCurrency:currency}} - {{getBiggest(item.prices) | fnCurrency:currency}}
      </div>
      <div *ngIf="item.prices.length > 0 && item.prices.length < 2">
        {{item.prices[0].price | fnCurrency:currency}}
      </div>
      <div *ngIf="item.color && item.year" class="wineColor">
        {{ item.color | translation }}
      </div>
      <div class="filters">
        <span *ngFor="let filter of item.filters">{{filter.name | translation}} </span>
      </div>
    </div>    
  </div>
  <div class="image" *ngIf="item.image" #container>
    <img [src]="getImgUrl(item.image, container.offsetWidth, leftContainer.offsetHeight)" />
  </div>
  </a>
</div>